import Loader from 'react-loader-spinner'
import React, { Component } from 'react';

export default class CustomLoader extends Component {
    render() {
     return(
      <Loader
         type="Oval"
         color="#00BFFF"
         height={100}
         width={100}
         visible ={this.props.show}

      />
     );
    }
 }
