import axios from 'axios';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.REACT_APP_CORS_URL;

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_NAMESPACE}`
});

let isRefreshing = false;
let refreshSubscribers = [];

instance.interceptors.request.use(function (config) {
    
    if (localStorage.getItem('access_token') !== null) {
        
        config.headers.Authorization = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
        if(config.url !== 'https://sso.direcas.com/api/oauth/token/revoke'){
            config.headers.User = localStorage.getItem('user');
        }
        
    }
   // console.log('prova3',config);

    //console.log(config);
    /*if (config.method === "post" || config.method === "put") {
        config.headers['Content-Type'] = process.env.REACT_APP_API_CONTENT_TYPE + "+json";
    }*/
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});



function refreshToken() {
    let config = {
        headers: {
            //"Content-Type": process.env.REACT_APP_API_CONTENT_TYPE + "+json",
        }
    }
    instance.get('auth/refresh', config)
        .then(response => {
            localStorage.setItem('access_token', response.data.access_token)
            localStorage.setItem('refresh_token', response.data.refresh_token)
            localStorage.setItem('expires_in', response.data.expires_in)
            localStorage.setItem('token_type', response.data.token_type)
            //dispatch(authSuccess(response.data.token));
            isRefreshing = false;
            onRrefreshed(response.data.token);
        })
        .catch(errors => {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('expires_in')
            localStorage.removeItem('token_type')
        });
}

function onRrefreshed(token) {
    refreshSubscribers.map(cb => cb(token));
}

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

function checkHader(config){
   let url = config.url;
   var request =  url.replace(config.baseURL,"");
   if(request === 'oauht/revoke'){
     return true;
   }

   return false;

}

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    //console.log('prova2',config);
    const { config, response: { status } } = error;
    const originalRequest = config;

    if( ! originalRequest.headers.hasOwnProperty('Authorization') && status === 401){
      return Promise.reject(error);
    }

    if (status === 401) {
        if (!isRefreshing) {
            isRefreshing = true;
            localStorage.removeItem('access_token')
            window.location = `${process.env.REACT_APP_ADMIN_PANEL_ENDPOINT}`;
        }



        const retryOrigReq = new Promise((resolve, reject) => {
            subscribeTokenRefresh(token => {
                // replace the expired token and retry
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                resolve(instance(originalRequest));
            });
        });
        return retryOrigReq;
    } else {
        return Promise.reject(error);
    }
});

export default instance;
