import AuthForm, { STATE_LOGIN } from 'components/Form/Auth';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

class AuthPage extends React.Component {


  constructor(props){
    super(props);
  }


  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <div></div>
    );
  }
}

export default AuthPage;
