import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/object';

const initialState = {
    customers : null,
    groups: null,
    meta: null,
    links: null,
    error: false,
    loading: true,
    empty: true,
    perPage: null,
    page: null,
    filters: null,
    include: null,
    order: null
};

const loadingStart = (state) => {
    return updateObject(state, { error: false, loading: true });
};

const setAccountants = (state, action) => {
    return updateObject(state, {
        customers: action.customers.data,
        meta: action.customers.meta,
        links: action.customers.links,
        error: false,
        loading: false,
        empty: false
    });
};

const fetchAccountantsFailed = (state) => {
    return updateObject(state, { error: true, loading: false });
};

const fetchAccountantsEmpty = (state) => {
    return updateObject(state, { error: false, loading: false, empty: true, customers: null });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADING_START_CUSTOMERS: return loadingStart(state);
        case actionTypes.SET_CUSTOMERS: return setAccountants(state, action);
        case actionTypes.FETCH_CUSTOMERS_FAILED: return fetchAccountantsFailed(state);
        case actionTypes.FETCH_CUSTOMERS_EMPTY: return fetchAccountantsEmpty(state);
        default: return state;
    }
};

export default reducer;