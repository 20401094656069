import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/object';

const initialState = {
	lounges: null,
	tablesState: null,
	meta: null,
	links: null,
	error: false,
	loading: true,
	empty: true,
	perPage: null,
	page: null,
	filters: null,
	include: null,
	order: null,
	pagination:null
};

const loadingStart = (state) => {
	return updateObject(state, { error: false, loading: true });
};

const setLounges = (state, action) => {
	return updateObject(state, {
		lounges: action.lounges.data
	});
};

const setTablesStatus = (state, action) => {
	return updateObject(state, {
		tablesState: action.tables.data.data,
		error: false,
		loading: false,
		empty: false,
	});
};

const fetchTablesStatusFailed = (state) => {
	return updateObject(state, { error: true, loading: false });
};

const fetchTableStatusEmpty = (state) => {
	return updateObject(state, { error: false, loading: false, empty: true, tablesState: null });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.LOADING_START_TABLES: return loadingStart(state);
		case actionTypes.SET_LOUNGES: return setLounges(state, action);
		case actionTypes.SET_TABLES_STATUS: return setTablesStatus(state, action);
		case actionTypes.FETCH_TABLES_STATUS_FAILED: return fetchTablesStatusFailed(state);
		case actionTypes.FETCH_TABLES_STATUS_EMPTY: return fetchTableStatusEmpty(state);
		default: return state;
	}
};

export default reducer;
