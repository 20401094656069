import logo from 'assets/img/logo/logo.png';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Alert, Input, Label } from 'reactstrap';

import * as actions from '../../store/actions/index';

class ResetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            email: {
                valid: true,
                value: "",
                touched: false,
            },
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.onResetPassword(this.state.email.value)
    };

    handleInputChange = (name, e, valid = true) => {
        const input = this.state[name]
        input.value = e.target.value
        input.touched = true
        input.valid = valid
        this.setState({
            [name]: input
        })
    }

    validateEmail = (e) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let valid = true
        if (!emailRex.test(e.target.value)) {
            valid = false
        }
        this.handleInputChange('email', e, valid)
    }

    render() {
        const {
            showLogo,
            usernameLabel,
            usernameInputProps,
            children,
            onLogoClick,
            loading,
            error,
            success
        } = this.props;
        const { email } = this.state

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    {showLogo && (
                        <div className="text-center pb-4">
                            <img
                                src={logo}
                                style={{ height: 60, cursor: 'pointer' }}
                                alt="logo"
                                onClick={onLogoClick}
                            />
                        </div>
                    )}
                    <p>
                        Inserisci la tua email e la tua password sarà resettata ed inviata alla tua casella di posta elettronica.
                    </p>
                    <FormGroup>
                        <Label for={usernameLabel}>{usernameLabel}</Label>
                        <Input {...usernameInputProps}
                            value={email.value}
                            onChange={this.validateEmail.bind(this)}
                            valid={email.valid && email.touched}
                            invalid={!email.valid && email.touched} />
                    </FormGroup>
                    <hr />
                    <Button
                        size="lg"
                        className="bg-gradient-theme-left border-0"
                        block
                        onClick={this.handleSubmit}>
                        Richiedi cambio password
                    </Button>
                    {children}
                </Form>
                <br />
                <Alert color={success ? 'success' : 'danger'} isOpen={loading === false && (error || success)}>
                    {success ? "Reset password effettuato. Controlla la tua casella di posta elettronica."
                    :
                    "Impossibile procedere con il reset della password. Verificare i dati inseriti"}
                </Alert>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    showLogo: PropTypes.bool,
    usernameLabel: PropTypes.string,
    usernameInputProps: PropTypes.object,
    onLogoClick: PropTypes.func,
};

ResetPassword.defaultProps = {
    showLogo: true,
    usernameLabel: 'Email',
    usernameInputProps: {
        type: 'email',
        placeholder: 'latua@email.com',
    },
    onLogoClick: () => { },
};

const mapStateToProps = state => {
    return {
        loading: state.resetPassword.loading,
        error: state.resetPassword.error,
        success: state.resetPassword.success,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onResetPassword: (email) => dispatch(actions.resetPassword(email))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
