import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { reducer as notifications } from 'react-notification-system-redux';
import authReducer from './auth';
import customerReducer from './customers';
import statsReducer from './statistics';
import tablesReducer from './tables';
import resetPassword from './resetPassword';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    customers: customerReducer,
    statistics: statsReducer,
    resetPassword: resetPassword,
    tables: tablesReducer,
    notifications
})
export default createRootReducer