import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import configureStore, { history } from './store/configureStore'

import App from './App';
import * as serviceWorker from './registerServiceWorker';

require('dotenv').config();

const store = configureStore()

const app = (
    <Provider store={store}>

        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>

    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
