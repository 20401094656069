import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/object';

const initialState = {
    token: null,
    refresh_token: null,
    expires_in: null,
    token_type: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    me: null,
    changingPassword: false,
    changingPasswordError: false,
    changingPasswordSuccess: false,
    showDialog: false,
};

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true , showDialog: false});
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.access_token,
        refresh_token: action.refresh_token,
        expires_in: action.expires_in,
        token_type: action.token_type,
        error: null,
        loading: false
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error.response.data.title ,
        loading: false,
        showDialog: true,
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, expire_on: null, loading: false , showDialog: false});
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const setMe = (state, action) => {
    return updateObject(state, { me: action.me })
}

const changePasswordStart = (state) => {
    return updateObject(state, { changingPassword: true, changingPasswordError: false })
}

const changePasswordFail = (state) => {
    return updateObject(state, { changingPassword: false, changingPasswordError: true })
}

const changePasswordSuccess = (state) => {
    return updateObject(state, { changingPassword: false, changingPasswordSuccess: true })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        case actionTypes.SET_ME: return setMe(state, action);
        case actionTypes.CHANGE_PASSWORD_START: return changePasswordStart(state);
        case actionTypes.CHANGE_PASSWORD_FAILED: return changePasswordFail(state);
        case actionTypes.CHANGE_PASSWORD_SUCCESS: return changePasswordSuccess(state);
        //case actionTypes.TOKEN_EXPIRED: return authLogout(state, action);
        default:
            return state;
    }
};

export default reducer;
