import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/object';
import arraySum from '../../utils/arraySum'

const initialState = {
    pointsStatistics: null,
    purchaseDetailStatistics: null,
    noPurchaseStatistics: null,
    receiptTotalStatistics: null,
    productTotalStatistics: null,
    cancelledSaleStatistics: null,
    selledProductsStatistics: null,
    customerDocStatistics: null,
    supplierDocStatistics: null,
    cashDeskMovementStatistics: null,
    accountingCardStatistics: null,
    product: null,
    pdfAvaiable: false,
    error: false,
    loading: false,
    pagination:null,
};

const loadingStart = (state) => {
    return updateObject(state, { error: false, loading: true, pdfAvaiable: false });
};

const setPointsStat = (state, action) => {
    let customers = []
    if (action.stats.included) {
        action.stats.included.forEach((customer) => {
            customers[customer.id] = customer.attributes
        })
    }
    return updateObject(state, {
        pointsStatistics: action.stats.data,
        customers: customers,
        error: false,
        loading: false,
        pdfAvaiable: true
    });
};

const fetchPointsStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false, pdfAvaiable: false });
};

const setPuchaseDetailStat = (state, action) => {
    let customers = []
    let products = []
    if (action.stats.included) {
        action.stats.included.forEach((data) => {
            switch (data.type) {
                case 'customers':
                    customers[data.id] = data.attributes
                    break;
                case 'products':
                    products[data.id] = data.attributes
                    break;
                default:
                    break;
            }
        })
    }
    return updateObject(state, {
        purchaseDetailStatistics: action.stats.data,
        customers: customers,
        products: products,
        error: false,
        loading: false,
        pdfAvaiable: true
    });
};

const fetchPurchaseDetailStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false, pdfAvaiable: false });
};

const setNoPurchaseStat = (state, action) => {
    let customers = []
    if (action.stats.included) {
        action.stats.included.forEach((customer) => {
            customers[customer.id] = customer.attributes
        })
    }
    return updateObject(state, {
        noPurchaseStatistics: action.stats.data,
        customers: customers,
        error: false,
        loading: false,
        pdfAvaiable: true
    });
};

const fetchNoPurchaseStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false, pdfAvaiable: false });
};

const setReceiptTotalStat = (state, action) => {
    let affiliates = {}
    if (action.stats.included) {
        action.stats.included.forEach((affiliate) => {
            affiliates[affiliate.id] = affiliate.attributes
        })
    }
    return updateObject(state, {
        receiptTotalStatistics: action.stats.data,
        affiliates: affiliates,
        error: false,
        loading: false,
        pdfAvaiable: true
    });
};

const fetchReceiptTotalStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false, pdfAvaiable: false });
};

const setProductTotalStat = (state, action) => {
    let affiliates = {}
    let products = {}
    let groups = {}
    let departments = {}
    let families = {}
    if (action.stats.included) {
        action.stats.included.forEach((data) => {
            switch (data.type) {
                case 'affiliates':
                    affiliates[data.id] = data.attributes
                    break;
                case 'products':
                    products[data.id] = data.attributes
                    break;
                case 'product-groups':
                    groups[data.id] = data.attributes
                    break;
                case 'product-departments':
                    departments[data.id] = data.attributes
                    break;
                case 'product-families':
                    families[data.id] = data.attributes
                    break;
                default:
                    break;
            }
        })
    }
    let out = {}
    let aggreagates = {}

    for (let i = 0; i < action.stats.data.length; i++) {
        const current = action.stats.data[i];
        if (out.hasOwnProperty(current.relationships.product.data.id)) {
            aggreagates[current.relationships.product.data.id].means.push(current.attributes.mean)
            aggreagates[current.relationships.product.data.id].margins.push(current.attributes.margin)
            aggreagates[current.relationships.product.data.id].promos.push(current.attributes.promo_perc)
            aggreagates[current.relationships.product.data.id].meansPrevYear.push(current.attributes.mean_prev_year)
            aggreagates[current.relationships.product.data.id].marginsPrevYear.push(current.attributes.margin_prev_year)
            aggreagates[current.relationships.product.data.id].promosPrevYear.push(current.attributes.promo_perc_prev_year)
        } else {
            aggreagates[current.relationships.product.data.id] = {
                'means': [current.attributes.mean],
                'margins': [current.attributes.margin],
                'promos': [current.attributes.promo_perc],
                'meansPrevYear': [current.attributes.mean_prev_year],
                'marginsPrevYear': [current.attributes.margin_prev_year],
                'promosPrevYear': [current.attributes.promo_perc_prev_year]
            }
        }
        if (out.hasOwnProperty(current.relationships.product.data.id)) {
            out[current.relationships.product.data.id].attributes.quantity += current.attributes.quantity
            out[current.relationships.product.data.id].attributes.amount += current.attributes.amount
            out[current.relationships.product.data.id].attributes.mean = arraySum(aggreagates[current.relationships.product.data.id].means) / aggreagates[current.relationships.product.data.id].means.length
            out[current.relationships.product.data.id].attributes.profit += current.attributes.profit
            out[current.relationships.product.data.id].attributes.margin = arraySum(aggreagates[current.relationships.product.data.id].margins) / aggreagates[current.relationships.product.data.id].margins.length
            out[current.relationships.product.data.id].attributes.promo_amount += current.attributes.promo_amount
            out[current.relationships.product.data.id].attributes.promo_perc = arraySum(aggreagates[current.relationships.product.data.id].promos) / aggreagates[current.relationships.product.data.id].promos.length
            out[current.relationships.product.data.id].attributes.quantity_prev_year += current.attributes.quantity_prev_year
            out[current.relationships.product.data.id].attributes.amount_prev_year += current.attributes.amount_prev_year
            out[current.relationships.product.data.id].attributes.mean_prev_year = arraySum(aggreagates[current.relationships.product.data.id].meansPrevYear) / aggreagates[current.relationships.product.data.id].meansPrevYear.length
            out[current.relationships.product.data.id].attributes.profit_prev_year += current.attributes.profit_prev_year
            out[current.relationships.product.data.id].attributes.margin_prev_year = arraySum(aggreagates[current.relationships.product.data.id].marginsPrevYear) / aggreagates[current.relationships.product.data.id].marginsPrevYear.length
            out[current.relationships.product.data.id].attributes.promo_amount_prev_year += current.attributes.promo_amount_prev_year
            out[current.relationships.product.data.id].attributes.promo_perc_prev_year = arraySum(aggreagates[current.relationships.product.data.id].promosPrevYear) / aggreagates[current.relationships.product.data.id].promosPrevYear.length
            continue;
        }
        out[current.relationships.product.data.id] = current

    }
    return updateObject(state, {
        productTotalStatistics: out,
        affiliates: affiliates,
        products: products,
        groups: groups,
        departments: departments,
        families: families,
        error: false,
        loading: false,
        pdfAvaiable: true
    });
};

const fetchProductTotalStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false, pdfAvaiable: false });
};

const setCancelledSaleStat = (state, action) => {
    let affiliates = {}
    if (action.stats.included) {
        action.stats.included.forEach((affiliate) => {
            affiliates[affiliate.id] = affiliate.attributes
        })
    }
    return updateObject(state, {
        cancelledSaleStatistics: action.stats.data,
        affiliates: affiliates,
        error: false,
        loading: false,
        pdfAvaiable: false
    });
};

const fetchCancelledStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false, pdfAvaiable: false });
};

const setSelledProductStat = (state, action) => {
    return updateObject(state, {
        selledProductsStatistics: action.stats.data.data,
        pagination: action.stats.data.meta.page,
        error: false,
        loading: false
    });
};

const fetchSelledProductStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false });
};


const setHistoryStat = (state, action) => {
    return updateObject(state, {
        history: action.stats,
        error: false,
        loading: false
    });
};

const fetchSHistoryStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false }
    );
  };
const setCustomerDocStat = (state, action) => {
    let affiliates = {}
    let customers = {}
    if (action.stats.included) {
        action.stats.included.forEach((data) => {
            switch (data.type) {
                case 'affiliates':
                    affiliates[data.id] = data.attributes
                    break;
                case 'customers':
                    customers[data.id] = data.attributes
                    break;
                default:
                    break;
            }
        })
    }
    return updateObject(state, {
        customerDocStatistics: action.stats.data,
        affiliates: affiliates,
        customers: customers,
        error: false,
        loading: false,
        pdfAvaiable: false
    });
};

const fetchCustomerDocStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false, pdfAvaiable: false });
};

const setSupplierDocStat = (state, action) => {
    let affiliates = {}
    let suppliers = {}
    if (action.stats.included) {
        action.stats.included.forEach((data) => {
            switch (data.type) {
                case 'affiliates':
                    affiliates[data.id] = data.attributes
                    break;
                case 'suppliers':
                    suppliers[data.id] = data.attributes
                    break;
                default:
                    break;
            }
        })
    }
    return updateObject(state, {
        supplierDocStatistics: action.stats.data,
        affiliates: affiliates,
        suppliers: suppliers,
        error: false,
        loading: false,
        pdfAvaiable: false
    });
};

const fetchSupplierDocStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false, pdfAvaiable: false });
};


const setCashDeskMovementStat = (state, action) => {
    let affiliates = {}
    if (action.stats.data.included) {
        action.stats.data.included.forEach((data) => {
            switch (data.type) {
                case 'affiliates':
                    affiliates[data.id] = data.attributes
                    break;
                default:
                    break;
            }
        })
    }
    return updateObject(state, {
        cashDeskMovementStatistics: action.stats.data.data,
        affiliates: affiliates,
        error: false,
        loading: false,
        pdfAvaiable: false,
        pagination: action.stats.data.meta.page
    });
};

const fetchCashDeskMovementStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false, pdfAvaiable: false });
};

const setAccountingCardStat = (state, action) => {
    return updateObject(state, {
        accountingCardStatistics: action.stats.data.data,
        error: false,
        loading: false,
        pdfAvaiable: false,
        pagination: action.stats.data.meta.page
    });
};

const fetchAccountingCardStatFailed = (state) => {
    return updateObject(state, { error: true, loading: false, pdfAvaiable: false });
};

const setProductGroupStat = (state, action) => {
    return updateObject(state, {
        productGroups: action.stats,
        error: false,
        loading: false
    });
};

const fetchProductGroupFailed = (state) => {
    return updateObject(state, { error: true, loading: false });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADING_START_STATISTICS: return loadingStart(state);
        case actionTypes.SET_POINTS_STATISTICS: return setPointsStat(state, action);
        case actionTypes.FETCH_POINTS_STATISTICS_FAILED: return fetchPointsStatFailed(state);
        case actionTypes.SET_PURCHASE_DETAIL_STATISTICS: return setPuchaseDetailStat(state, action);
        case actionTypes.FETCH_PURCHASE_DETAIL_STATISTICS_FAILED: return fetchPurchaseDetailStatFailed(state);
        case actionTypes.SET_CUSTOMER_NO_PURCHASE_STATISTICS: return setNoPurchaseStat(state, action);
        case actionTypes.FETCH_CUSTOMER_NO_PURCHASE_STATISTICS_FAILED: return fetchNoPurchaseStatFailed(state);
        case actionTypes.SET_RECEIPT_TOTAL_STATISTICS: return setReceiptTotalStat(state, action);
        case actionTypes.FETCH_RECEIPT_TOTAL_STATISTICS_FAILED: return fetchReceiptTotalStatFailed(state);
        case actionTypes.SET_PRODUCT_TOTAL_STATISTICS: return setProductTotalStat(state, action);
        case actionTypes.FETCH_PRODUCT_TOTAL_STATISTICS_FAILED: return fetchProductTotalStatFailed(state);
        case actionTypes.SET_CANCELLED_SALE_STATISTICS: return setCancelledSaleStat(state, action);
        case actionTypes.FETCH_CANCELLED_SALE_STATISTICS_FAILED: return fetchCancelledStatFailed(state);
        case actionTypes.SET_SELLED_PRODUCT_STATISTICS: return setSelledProductStat(state, action);
        case actionTypes.FETCH_SELLED_PRODUCT_STATISTICS_FAILED: return fetchSelledProductStatFailed(state);
        case actionTypes.SET_HISTORY_STATISTICS: return setHistoryStat(state,action);
        case actionTypes.FETCH_HISTORY_STATISTICS_FAILED: return fetchSHistoryStatFailed(state);
        case actionTypes.SET_CUSTOMER_DOC_STATISTICS: return setCustomerDocStat(state, action);
        case actionTypes.FETCH_CUSTOMER_DOC_STATISTICS_FAILED: return fetchCustomerDocStatFailed(state);
        case actionTypes.SET_SUPPLIER_DOC_STATISTICS: return setSupplierDocStat(state, action);
        case actionTypes.FETCH_SUPPLIER_DOC_STATISTICS_FAILED: return fetchSupplierDocStatFailed(state);
        case actionTypes.SET_CASH_DESK_MOVEMENT_STATISTICS: return setCashDeskMovementStat(state, action);
        case actionTypes.FETCH_CASH_DESK_MOVEMENT_STATISTICS_FAILED: return fetchCashDeskMovementStatFailed(state);
        case actionTypes.SET_ACCOUNTING_CARD_STATISTICS: return setAccountingCardStat(state, action);
        case actionTypes.FETCH_ACCOUNTING_CARD_STATISTICS_FAILED: return fetchAccountingCardStatFailed(state);
        case actionTypes.SET_PRODUCT_GROUPS_STATISTICS: return setProductGroupStat(state,action);
        case actionTypes.FETCH_PRODUCT_GROUPS_STATISTICS_FAILED: return fetchProductGroupFailed(state);
        default: return state;
    }
};

export default reducer;
