import * as actionTypes from './actionTypes';
import api from '../../api';
import { createQueryString } from '../../utils/queryString';

export const loadingStart = () => {
	return {
		type: actionTypes.LOADING_START_TABLES
	};
};

export const setLounges = (lounges) => {
	return {
		type: actionTypes.SET_LOUNGES,
		lounges: lounges
	};
};

export const fetchLoungesFailed = () => {
	return {
		type: actionTypes.FETCH_LOUNGES_FAILED
	};
};

export const setLoungesEmpty = () => {
	return {
		type: actionTypes.FETCH_LOUNGES_EMPTY
	};
};

export const tokenExpired = () => {
	return {
		type: actionTypes.TOKEN_EXPIRED
	}
}

export const loadLounges = (page, perPage, filters, include, order = {}, params = null) => {
	return dispatch => {
		dispatch(loadingStart());
		const queryString = createQueryString(page, perPage, filters, include, order, params);
		api.get(`lounges${queryString}`)
			.then(response => {
				switch (response.status) {
					case 204:
						dispatch(setLoungesEmpty());
						break;
					default:
						dispatch(setLounges(response.data));
						break;
				}
			})
			.catch(error => {
				if (process.env.NODE_ENV === 'development') {
					console.log(error);
				}
				if (error.response) {
					if (error.response.status === 401) {
						if (error.response.data.code === "107") {
							dispatch(tokenExpired());
						}
					}
				}
				dispatch(fetchLoungesFailed());
			});
	};
};

export const loadTablesStatus = (page, perPage, filters, include, order = {}, params = null) => {
	return dispatch => {
		dispatch(loadingStart());
		const queryString = createQueryString(page, perPage, filters, include, order, params);
		api.get(`tables-state${queryString}`)
			.then(response => {
				switch (response.status) {
					case 204:
						dispatch(setTablesEmpty());
						break;
					default:
						dispatch(setTables(response));
						break;
				}
			})
			.catch(error => {
				if (process.env.NODE_ENV === 'development') {
					console.log(error);
				}
				if (error.response) {
					if (error.response.status === 401) {
						if (error.response.data.code === "107") {
							dispatch(tokenExpired());
						}
					}
				}
				dispatch(fetchTablesFailed());
			});
	};
};

export const setTables = (tables) => {
	return {
		type: actionTypes.SET_TABLES_STATUS,
		tables: tables
	};
};

export const fetchTablesFailed = () => {
	return {
		type: actionTypes.FETCH_TABLES_STATUS_FAILED
	};
};

export const setTablesEmpty = () => {
	return {
		type: actionTypes.FETCH_TABLES_STATUS_EMPTY
	};
};
