import logo from 'assets/img/logo/logo.png';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Input, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, } from 'reactstrap';

import * as actions from '../../store/actions/index';

class Auth extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: {
        valid: true,
        value: "",
        touched: false,
      },
      password: {
        valid: true,
        value: "",
        touched:false,
      },
      modal:{
        backdrop:true,
        className: 'error',
        show:this.props.showDialog
      },
      show:this.props.showDialog
    }
  }



  toggle = modalType => () => {
    if (!modalType) {
      return this.setState({
        show: !this.state.modal.show
      });
    }

    this.setState({
      [`modal_${modalType}`]: !this.state[`modal_${modalType}`],
    });
  };


  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();
    this.props.onChangeAuthState(authState);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.onAuth(this.state.email.value, this.state.password.value)
  };

  handleInputChange = (name, e) => {
    const input = this.state[name]
    input.value = e.target.value
    input.touched = true
    input.valid = true
    this.setState({
      [name]: input
    })
  }


  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <div>
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={logo}
              style={{ height: 60, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input {...usernameInputProps}
            value={this.state.email.value}
            onChange={this.handleInputChange.bind(this, 'email')}
            valid={this.state.email.valid && this.state.email.touched}
            invalid={!this.state.email.valid && this.state.email.touched}/>
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input {...passwordInputProps}
            value={this.state.password.value}
            onChange={this.handleInputChange.bind(this, 'password')}
            valid={this.state.email.valid && this.state.email.touched}
            invalid={!this.state.email.valid && this.state.email.touched}/>
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input {...confirmPasswordInputProps} />
          </FormGroup>
        )}
        <FormGroup check>
          <Label check>
            <Input type="checkbox" />{' '}
            {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
          </Label>
        </FormGroup>
        <hr />
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={this.handleSubmit}>
          {this.renderButtonText()}
        </Button>

        {children}
      </Form>
        <Modal isOpen={this.state.show} toggle={this.toggle} className={this.state.modal.className} backdrop={this.state.modal.backdrop}>
          <ModalHeader toggle={this.toggle()}>Errore</ModalHeader>
          <ModalBody>
          Credenziali errate! Riprovare.
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle()}>Chiudi</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

Auth.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

Auth.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'latua@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'la tua password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    showDialog: state.auth.showDialog

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
