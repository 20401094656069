import React from 'react';
import Typography from 'components/Typography';
import {
    Col,
    Row,
    Button
} from 'reactstrap';
import { Link } from 'react-router-dom'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // Add some default error states
        this.state = {
            error: false,
            info: null,
        };
    }

    componentDidCatch(error, info) {
        // Something happened to one of my children.
        // Add error to state
        this.setState({
            error: error,
            info: info,
        });
    }

    render() {
        if (this.state.error) {
            return (
                <Row
                    style={{
                        height: '100vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Col md={6} lg={4} className="text-center">
                        <Typography type="display-1" className="text-danger">500</Typography>
                        <p>Qualcosa è andato storto. Riprova o contatta l'assistenza <a href="mailto:sviluppo@direcas.com">sviluppo@direcas.com</a>.</p>
                        <Link to="/"><Button>Torna alla home</Button></Link>
                    </Col>
                </Row>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;