export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REFRESH = 'AUTH_REFRESH';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED'
export const SET_ME = 'SET_ME';

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const LOADING_START_CUSTOMERS = "LOADING_START_CUSTOMERS"
export const SET_CUSTOMERS = "SET_CUSTOMERS"
export const FETCH_CUSTOMERS_FAILED = "FETCH_CUSTOMERS_FAILED"
export const FETCH_CUSTOMERS_EMPTY = "FETCH_CUSTOMERS_EMPTY"

export const LOADING_START_STATISTICS = 'LOADING_START_STATISTICS'
export const SET_POINTS_STATISTICS = "SET_POINTS_STATISTICS"
export const FETCH_POINTS_STATISTICS_FAILED = "FETCH_POINTS_STATISTICS_FAILED"
export const SET_PURCHASE_DETAIL_STATISTICS = "SET_PURCHASE_DETAIL_STATISTICS"
export const FETCH_PURCHASE_DETAIL_STATISTICS_FAILED = "FETCH_PURCHASE_DETAIL_STATISTICS_FAILED"
export const SET_CUSTOMER_NO_PURCHASE_STATISTICS = "SET_CUSTOMER_NO_PURCHASE_STATISTICS"
export const FETCH_CUSTOMER_NO_PURCHASE_STATISTICS_FAILED = "FETCH_CUSTOMER_NO_PURCHASE_STATISTICS_FAILED"
export const SET_RECEIPT_TOTAL_STATISTICS = "SET_RECEIPT_TOTAL_STATISTICS"
export const FETCH_RECEIPT_TOTAL_STATISTICS_FAILED = "FETCH_RECEIPT_TOTAL_STATISTICS_FAILED"
export const SET_PRODUCT_TOTAL_STATISTICS = "SET_PRODUCT_TOTAL_STATISTICS"
export const FETCH_PRODUCT_TOTAL_STATISTICS_FAILED = "FETCH_PRODUCT_TOTAL_STATISTICS_FAILED"
export const SET_CANCELLED_SALE_STATISTICS = "SET_CANCELLED_SALE_STATISTICS"
export const FETCH_CANCELLED_SALE_STATISTICS_FAILED = "FETCH_CANCELLED_SALE_STATISTICS_FAILED"
export const SET_SELLED_PRODUCT_STATISTICS = "SET_SELLED_PRODUCT_STATISTICS"
export const FETCH_SELLED_PRODUCT_STATISTICS_FAILED = "FETCH_SELLED_PRODUCT_STATISTICS_FAILED"

export const SET_HISTORY_STATISTICS = "SET_HISTORY_STATISTICS"
export const FETCH_HISTORY_STATISTICS_FAILED ="FETCH_HISTORY_STATISTICS_FAILED"

export const SET_CUSTOMER_DOC_STATISTICS = "SET_CUSTOMER_DOC_STATISTICS"
export const FETCH_CUSTOMER_DOC_STATISTICS_FAILED = "FETCH_CUSTOMER_DOC_STATISTICS_FAILED"
export const SET_SUPPLIER_DOC_STATISTICS = "SET_SUPPLIER_DOC_STATISTICS"
export const FETCH_SUPPLIER_DOC_STATISTICS_FAILED = "FETCH_SUPPLIER_DOC_STATISTICS_FAILED"
export const SET_CASH_DESK_MOVEMENT_STATISTICS = "SET_CASH_DESK_MOVEMENT_STATISTICS"
export const FETCH_CASH_DESK_MOVEMENT_STATISTICS_FAILED = "FETCH_CASH_DESK_MOVEMENT_STATISTICS_FAILED"
export const SET_ACCOUNTING_CARD_STATISTICS = "SET_ACCOUNTING_CARD_STATISTICS"
export const FETCH_ACCOUNTING_CARD_STATISTICS_FAILED = "FETCH_ACCOUNTING_CARD_STATISTICS_FAILED"

export const LOADING_START_TABLES = 'LOADING_START_TABLES'
export const SET_LOUNGES = "SET_LOUNGES"
export const FETCH_LOUNGES_EMPTY = "SET_LOUNGES_EMPTY"
export const FETCH_LOUNGES_FAILED = "FETCH_LOUNGES_FAILED"
export const SET_TABLES_STATUS = "SET_TABLES_STATUS"
export const FETCH_TABLES_STATUS_FAILED = "FETCH_TABLES_STATUS_FAILED"
export const FETCH_TABLES_STATUS_EMPTY = "FETCH_TABLES_STATUS_EMPTY"

export const SET_PRODUCT_GROUPS_STATISTICS = "SET_PRODUCT_GROUPS_STATISTICS"
export const FETCH_PRODUCT_GROUPS_STATISTICS_FAILED ="FETCH_PRODUCT_GROUPS_STATISTICS_FAILED"
