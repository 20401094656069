import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';

import SourceLink from 'components/SourceLink';

const Footer = () => {
  return (
    <Navbar>
      <Nav navbar>
        <NavItem>
          &copy; {new Date().getFullYear()} <SourceLink>DI.RE.CA.S s.r.l.</SourceLink> - Piva 02591630823 – Tel. 091 6259205 – info@direcas.com
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;