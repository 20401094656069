import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import ErrorBoundary from './components/ErrorBoundary';
import './styles/reduction.scss';
import CustomLoader from './components/Widget/CustomLoader';
import './styles/style.css';

const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const CustomerPointsPage = React.lazy(() => import('pages/CustomerPointsPage'));
const CustomerPurchaseDetailPage = React.lazy(() => import('pages/CustomerPurchaseDetailPage'));
const CustomerNoPurchasePage = React.lazy(() => import('pages/CustomerNoPurchasePage'));
const SignOutPage = React.lazy(() => import('pages/SignOutPage'));
const ProfilePage = React.lazy(() => import('pages/ProfilePage'));
const ReceiptTotalPage = React.lazy(() => import('pages/ReceiptTotalPage'));
const ProductSalePage = React.lazy(() => import('pages/ProductSalePage'));
const RevenueComparisionPage = React.lazy(() => import('pages/RevenueComparisionPage'));
const CancelledSalePage = React.lazy(() => import('pages/CancelledSalePage'))
const SelledProductsPage = React.lazy(() => import('pages/SelledProductPage'))

const TablesStatusPage = React.lazy(() => import('pages/TablesStatusPage'))
const CustomerDocTotalPage = React.lazy(() => import('pages/CustomerDocTotalPage'))
const SupplierDocTotalPage = React.lazy(() => import('pages/SupplierDocTotalPage'))
const CashDeskMovementPage = React.lazy(() => import('pages/CashDeskMovementPage'))
const AccountingCardPage = React.lazy(() => import('pages/AccountingCardPage'))
const GroupsStatisticsPage = React.lazy(() => import('pages/GroupsStatisticsPage'))


const Page404 = React.lazy(() => import('pages/errors/404'));

class App extends React.Component {
  constructor(props) {
    super(props);
    var access_token = null;
    const params = new URLSearchParams(props.location.search);
    //token = params.get('access_token'); //

    if(params.get('access_token') !== 'undefined' && params.get('access_token') !== null){
      access_token =  params.get('access_token');
    }
    this.state = {
      autoSignupStarted: false,
      token:access_token,
    }


  }

  componentDidMount() {
    if(this.state.token !== null){
      this.props.getMe(this.state.token);
    }else{
      this.props.onTryAutoSignup();
      this.setState({ autoSignupStarted: true });
    }
    if((this.state.autoSignupStarted === false
        && localStorage.getItem('access_token') == null
        && this.state.token === null) || this.props.error !== null ){
          window.location = `${process.env.REACT_APP_ADMIN_PANEL_ENDPOINT}`;

    }

  }

  render() {


    let routes = null;
    let redirect = null;

    routes = (
      <Switch>
        <LayoutRoute
          exact
          path="/login"
          layout={EmptyLayout}
          component={props => (
            <AuthPage {...props} />
          )}
        />
        <LayoutRoute
          exact
          path="/reset-password"
          layout={EmptyLayout}
          component={props => (
            <ResetPasswordPage {...props} />
          )}
        />
        <Redirect to="/login" />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      if (this.props.location.pathname === '/login') {
        redirect = <Redirect to="/" />
      }
      routes = <MainLayout breakpoint={this.props.breakpoint}>
        <React.Suspense fallback={<PageSpinner />}>
          <Switch>
            <Route exact path="/customer-points" render={props => <CustomerPointsPage {...props} />} />
            <Route exact path="/customer-purchases" render={props => <CustomerPurchaseDetailPage {...props} />} />
            <Route exact path="/customer-no-purchases" render={props => <CustomerNoPurchasePage {...props} />} />
            <Route exact path="/customer-document-totals" render={props => <CustomerDocTotalPage {...props} />} />
            <Route exact path="/receipt-totals" render={props => <ReceiptTotalPage {...props} />} />
            <Route exact path="/products-sales" render={props => <ProductSalePage {...props} />} />
            <Route exact path="/revenue-comparision" render={props => <RevenueComparisionPage {...props} />} />
            <Route exact path="/groups-statistics" render={props => <GroupsStatisticsPage {...props} />} />
            <Route exact path="/cancelled-sales" render={props => <CancelledSalePage {...props} />} />
            <Route exact path="/selled-products" render={props => <SelledProductsPage {...props} />} />
            <Route exact path="/tables-status" render={props => <TablesStatusPage {...props} />} />
            <Route exact path="/supplier-document-totals" render={props => <SupplierDocTotalPage {...props} />} />
            <Route exact path="/cash-desk-movements" render={props => <CashDeskMovementPage {...props} />} />
            <Route exact path="/accounting-cards" render={props => <AccountingCardPage {...props} />} />
            <Route exact path="/profile" render={props => <ProfilePage {...props} />} />
            <Route exact path="/sign-out" render={props => <SignOutPage {...props} />} />
            <Route exact path="/" render={props => <DashboardPage {...props} />} />
            { redirect }
            <Route path="*" render={props => <Page404 {...props} />} />
          </Switch>
        </React.Suspense>
      </MainLayout>
    }
    return (
      <ErrorBoundary>
        {routes}
      </ErrorBoundary>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    getMe: (token) => dispatch(actions.me(token))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(componentQueries(query)(App)));
