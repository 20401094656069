import * as actionTypes from './actionTypes';
import api from '../../api';
import { createQueryString } from '../../utils/queryString';
import Notifications from 'react-notification-system-redux';

export const loadingStart = () => {
    return {
        type: actionTypes.LOADING_START_STATISTICS
    };
};

export const setPointStatCustomers = (stats) => {
    return {
        type: actionTypes.SET_POINTS_STATISTICS,
        stats: stats
    };
};

export const fetchPointStatCustomersFailed = () => {
    return {
        type: actionTypes.FETCH_POINTS_STATISTICS_FAILED
    };
};

export const tokenExpired = () => {
    return {
        type: actionTypes.TOKEN_EXPIRED
    }
}

export const loadPointStatCustomers = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`purchase-aggregations${queryString}`)
            .then(response => {
                dispatch(setPointStatCustomers(response.data));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(Notifications.error({
                    message: 'Errore caricamento dati',
                }));
                dispatch(fetchPointStatCustomersFailed());
            });
    };
};

export const loadPurchaseDetailStatCustomers = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`purchase-detail-products${queryString}`)
            .then(response => {
                dispatch(setPurchaseDetailStatCustomers(response.data));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchPurchaseDetailStatCustomersFailed());
            });
    };
};

export const setPurchaseDetailStatCustomers = (stats) => {
    return {
        type: actionTypes.SET_PURCHASE_DETAIL_STATISTICS,
        stats: stats
    };
};

export const fetchPurchaseDetailStatCustomersFailed = () => {
    return {
        type: actionTypes.FETCH_PURCHASE_DETAIL_STATISTICS_FAILED
    };
};

export const loadNoPurchaseStatCustomers = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`customer-last-purchases${queryString}`)
            .then(response => {
                dispatch(setNoPurchaseStatCustomers(response.data));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchNoPurchaseStatCustomersFailed());
            });
    };
};

export const setNoPurchaseStatCustomers = (stats) => {
    return {
        type: actionTypes.SET_CUSTOMER_NO_PURCHASE_STATISTICS,
        stats: stats
    };
};

export const fetchNoPurchaseStatCustomersFailed = () => {
    return {
        type: actionTypes.FETCH_CUSTOMER_NO_PURCHASE_STATISTICS_FAILED
    };
};

export const loadReceiptTotalStat = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`receipt-totals${queryString}`)
            .then(response => {
                dispatch(setReceiptTotalsStat(response.data));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchReceiptTotalsFailed());
            });
    };
};

export const setReceiptTotalsStat = (stats) => {
    return {
        type: actionTypes.SET_RECEIPT_TOTAL_STATISTICS,
        stats: stats
    };
};

export const fetchReceiptTotalsFailed = () => {
    return {
        type: actionTypes.FETCH_RECEIPT_TOTAL_STATISTICS_FAILED
    };
};

export const loadProductTotalStat = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`product-totals${queryString}`)
            .then(response => {
                dispatch(setProductTotalsStat(response.data));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchProductTotalsFailed());
            });
    };
};

export const loadHistoricalStat = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`historicals-stats${queryString}`)
            .then(response => {
                dispatch(setHistoryStat(response.data));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchHistoryStatFailed());
            });
    };
};


export const setHistoryStat = (stats) => {
    return {
        type: actionTypes.SET_HISTORY_STATISTICS,
        stats: stats
    };
};

export const fetchHistoryStatFailed = () => {
    return {
        type: actionTypes.FETCH_HISTORY_STATISTICS_FAILED
    };
};

export const setProductTotalsStat = (stats) => {
    return {
        type: actionTypes.SET_PRODUCT_TOTAL_STATISTICS,
        stats: stats
    };
};



export const fetchProductTotalsFailed = () => {
    return {
        type: actionTypes.FETCH_PRODUCT_TOTAL_STATISTICS_FAILED
    };
};



export const loadProductGroupsStats = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`product-groups-statistics${queryString}`)
            .then(response => {
                dispatch(setProductGroupStat(response.data));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchProductGroupStatFailed());
            });
    };
};


export const setProductGroupStat = (stats) => {
    return {
        type: actionTypes.SET_PRODUCT_GROUPS_STATISTICS,
        stats: stats
    };
};

export const fetchProductGroupStatFailed = () => {
    return {
        type: actionTypes.FETCH_PRODUCT_GROUPS_STATISTICS_FAILED
    };
};

export const loadCancelledSaleStat = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`cancelled-sales${queryString}`)
            .then(response => {
                dispatch(setCancelledSaleStat(response.data));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchCancelledSaleFailed());
            });
    };
};

export const setCancelledSaleStat = (stats) => {
    return {
        type: actionTypes.SET_CANCELLED_SALE_STATISTICS,
        stats: stats
    };
};

export const fetchCancelledSaleFailed = () => {
    return {
        type: actionTypes.FETCH_CANCELLED_SALE_STATISTICS_FAILED
    };
};

export const loadSelledProductsStat = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`selled-products${queryString}`)
            .then(response => {
                dispatch(setSelledProductStat(response));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchSelledProductFailed());
            });
    };
};

export const setSelledProductStat = (stats) => {
    return {
        type: actionTypes.SET_SELLED_PRODUCT_STATISTICS,
        stats: stats
    };
};

export const fetchSelledProductFailed = () => {
    return {
        type: actionTypes.FETCH_SELLED_PRODUCT_STATISTICS_FAILED
    };
};

export const loadCustomerDocStat = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`customer-document-totals${queryString}`)
            .then(response => {
                dispatch(setCustomerDocStat(response.data));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchCustomerDocFailed());
            });
    };
};

export const setCustomerDocStat = (stats) => {
    return {
        type: actionTypes.SET_CUSTOMER_DOC_STATISTICS,
        stats: stats
    };
};

export const fetchCustomerDocFailed = () => {
    return {
        type: actionTypes.FETCH_CUSTOMER_DOC_STATISTICS_FAILED
    };
};

export const loadSupplierDocStat = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`supplier-document-totals${queryString}`)
            .then(response => {
                dispatch(setSupplierDocStat(response.data));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchSupplierDocFailed());
            });
    };
};

export const setSupplierDocStat = (stats) => {
    return {
        type: actionTypes.SET_SUPPLIER_DOC_STATISTICS,
        stats: stats
    };
};

export const fetchSupplierDocFailed = () => {
    return {
        type: actionTypes.FETCH_SUPPLIER_DOC_STATISTICS_FAILED
    };
};

export const loadCashDeskMovementStat = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`cash-desk-movements${queryString}`)
            .then(response => {
                dispatch(setCashDeskMovementStat(response));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchCashDeskMovementFailed());
            });
    };
};

export const setCashDeskMovementStat = (stats) => {
    return {
        type: actionTypes.SET_CASH_DESK_MOVEMENT_STATISTICS,
        stats: stats
    };
};

export const fetchCashDeskMovementFailed = () => {
    return {
        type: actionTypes.FETCH_CASH_DESK_MOVEMENT_STATISTICS_FAILED
    };
};

export const loadAccountingCardStat = (page, perPage, filters, include, order = {}, params = null) => {
    return dispatch => {
        dispatch(loadingStart());
        const queryString = createQueryString(page, perPage, filters, include, order, params);
        api.get(`accounting-cards${queryString}`)
            .then(response => {
                dispatch(setAccountingCardStat(response));
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.code === "107") {
                            dispatch(tokenExpired());
                        }
                    }
                }
                dispatch(fetchAccountingCardFailed());
            });
    };
};

export const setAccountingCardStat = (stats) => {
    return {
        type: actionTypes.SET_ACCOUNTING_CARD_STATISTICS,
        stats: stats
    };
};

export const fetchAccountingCardFailed = () => {
    return {
        type: actionTypes.FETCH_ACCOUNTING_CARD_STATISTICS_FAILED
    };
};
