import {isEmpty} from './object'

export const createQueryString = (page, perPage, filters, includes = [], order = {}, params = null) => {
    let query = "?";
    //FILTRI DA UNIFORMARE 
    if (page !== null) {
        query += "&page=" + page;
    }
    if (perPage !== null) {
        query += "&per_page=" + perPage;
    }

    if (page !== null) {
        query += "&page[number]=" + page;
    }
    if (perPage !== null) {
        query += "&page[size]=" + perPage;
    }
    if (filters !== null) {
        for (const [key, value] of Object.entries(filters)) {
            if (value !== "" && value !== null) {
                if (value instanceof Object) {
                    if (Object.values(value)[0] !== null && Object.values(value)[0] !== "") {
                        query += "&filter[" + key + "." + Object.keys(value)[0] + "]=" + Object.values(value)[0];
                    }
                } else {
                    query += "&filter[" + key + "]=" + value;
                }
            }
        }
    }
    if (includes !== null && includes.length > 0) {
        query += "&include=" + includes.join()
    }
    if (!isEmpty(order)) {
        switch (order.direction) {
            case 'ASC':
                query += "&sort=" + order.by;
                break;
            case 'DESC':
                query += "&sort=-" + order.by;
                break;
            default:
                break;
        }
    }
    if (params !== null) {
        query += params;
    }
    return query;
}
