import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import Form from 'components/Form/ResetPassword';

class ResetPasswordPage extends React.Component {
    handleLogoClick = () => {
        this.props.history.push('/');
    };

    render() {
        return (
            <Row
                style={{
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Col md={6} lg={4}>
                    <Card body>
                        <Form
                            onLogoClick={this.handleLogoClick}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default ResetPasswordPage;
