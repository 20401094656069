import logo from 'assets/img/logo/logo.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import { connect } from 'react-redux';
import { BUSINESS_AREA } from 'utils/constants';
import {
  MdDashboard,
  MdTimeline,
  MdKeyboardArrowDown,
  MdLocalGroceryStore,
  MdControlPointDuplicate,
  MdShowChart,
  MdCancel,
  MdReceipt,
  MdEuroSymbol,
  MdMultilineChart,
  MdInfo,
  MdLocalDining,
  MdSwapVert,
  MdDescription
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';

const customerStatContents = [
  { to: '/customer-points', name: 'Punti clienti', exact: true, Icon: MdControlPointDuplicate },
  {
    to: '/customer-purchases',
    name: 'Acquisti clienti',
    exact: true,
    Icon: MdLocalGroceryStore,
    restrictedTo:[]
  },
  {
    to: '/customer-no-purchases',
    name: 'Clienti no acquisti',
    exact: true,
    Icon: MdCancel,
    restrictedTo:[]
  },
  {
    to: '/customer-document-totals',
    name: 'Fatturato clienti',
    exact: true,
    Icon: MdEuroSymbol,
    restrictedTo:[]
  }
];

const supplierStatContents = [
  {
    to: '/supplier-document-totals',
    name: 'Fatturato fornitori',
    exact: true,
    Icon: MdEuroSymbol,
    restrictedTo:[]
  }
];

const SellStatContents = [
  { to: '/receipt-totals', name: 'Totali scontrini', exact: true, Icon: MdReceipt, restrictedTo:[] },
  { to: '/products-sales', name: 'Prodotti', exact: true, Icon: MdEuroSymbol, restrictedTo:[] },
  { to: '/revenue-comparision', name: 'Paragone incassi', exact: true, Icon: MdTimeline, restrictedTo:[] },
  { to: '/groups-statistics', name: 'Gruppi Statistiche', exact: true, Icon: MdMultilineChart, restrictedTo:[] },
  { to: '/cancelled-sales', name: 'Documenti annullati', exact: true, Icon: MdCancel, restrictedTo:[] },
];

const navItems = [
  { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard, restrictedTo:[] },
  { to: '/selled-products', name: 'Lista articoli', exact: true, Icon: MdInfo,restrictedTo:[] },
  { to: '/tables-status', name: 'Situazione tavoli', exact: true, Icon: MdLocalDining, restrictedTo:[BUSINESS_AREA.restaurant] },
  { to: '/cash-desk-movements', name: 'Incassi convenzioni', exact: true, Icon: MdSwapVert, restrictedTo:[] },
  { to: '/accounting-cards', name: 'Situazione contabile', exact: true, Icon: MdDescription, restrictedTo:[] },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenStatistics: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  toogleMenu = (e) => {
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  }


handleShowNavItem = (array,restricted) => {

  if(restricted.length === 0){
    return true;
  }
  var include = false;

  for (var i = 0; i < array.length; i++) {
    if(restricted.includes(array[i])){
      include = true;
      return include;
    }
  }
  return include;
}

  render() {
    var business_area = [];
    if(this.props.auth.me !== null){
      business_area =  this.props.auth.me.business_area.map((obj) =>{
           return obj.code;
       });

    }
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo}
                height="45"
                className="pr-2"
                alt=""
              />
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon , restrictedTo }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className={`text-uppercase ${this.handleShowNavItem(business_area,restrictedTo) === false ? `d-none` : '' }`}
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                  onClick={this.toggleMenu}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('SellStatistics')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdMultilineChart className={bem.e('nav-item-icon')} />
                  <span className="text-uppercase">Statistiche vendite</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenSellStatistics
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenSellStatistics}>
              {SellStatContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('CustomerStatistics')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdShowChart className={bem.e('nav-item-icon')} />
                  <span className="text-uppercase">Statistiche cliente</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenCustomerStatistics
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenCustomerStatistics}>
              {customerStatContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('SupplierStatistics')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdShowChart className={bem.e('nav-item-icon')} />
                  <span className="text-uppercase">Statistiche fornitore</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenSupplierStatistics
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenSupplierStatistics}>
              {supplierStatContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
          </Nav>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = state => {
    return {
      auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
