import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/object';

const initialState = {
    error: false,
    loading: false,
    success: false,
};

const setResetPasswordStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const setResetPasswordFailed = (state, action) => {
    return updateObject(state, {
        error: true,
        loading: false
    });
};

const setResetPasswordSuccess = (state, action) => {
    return updateObject(state, { error: null, loading: false, success: true });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_PASSWORD_START: return setResetPasswordStart(state, action);
        case actionTypes.RESET_PASSWORD_SUCCESS: return setResetPasswordSuccess(state, action);
        case actionTypes.RESET_PASSWORD_FAILED: return setResetPasswordFailed(state, action);
        //case actionTypes.TOKEN_EXPIRED: return authLogout(state, action);
        default:
            return state;
    }
};

export default reducer;