import * as actionTypes from './actionTypes';
import api from '../../api';
import Notifications from 'react-notification-system-redux';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, refresk_token, expiration, token_type) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        access_token: token,
        refresh_token: refresk_token,
        expires_in: expiration,
        token_type: token_type
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
    };
};

export const logout = () => {
    if (localStorage.getItem('access_token') !== null) {
        api.post(`${process.env.REACT_APP_SSO_ENDPOINT}api/oauth/token/revoke`)
        .then(() => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('expires_in');
            localStorage.removeItem('token_type');
            window.location = `${process.env.REACT_APP_ADMIN_PANEL_ENDPOINT}`;
        })
    }
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (email, password) => {
    // return dispatch => {
    //     dispatch(authStart());
    //     const authData = {
    //         grant_type: process.env.REACT_APP_API_GRANT_TYPE,
    //         client_id: process.env.REACT_APP_API_CLIENT_ID,
    //         client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
    //         scope: process.env.REACT_APP_API_SCOPE,
    //         username: email,
    //         password: password
    //     }
    //     console.log('token');
    //
    //     api.post(`${process.env.REACT_APP_API_URL}oauth/token`, authData)
    //         .then(response => {
    //             localStorage.setItem('access_token', response.data.access_token);
    //             localStorage.setItem('refresh_token', response.data.refresh_token);
    //             localStorage.setItem('expires_in', response.data.expires_in);
    //             localStorage.setItem('token_type', response.data.token_type);
    //             dispatch(authSuccess(
    //                 response.data.access_token,
    //                 response.data.refresh_token,
    //                 response.data.expires_in,
    //                 response.data.token_type));
    //             dispatch(me())
    //         })
    //         .catch(errors => {
    //             dispatch(authFail(errors));
    //         });
    // };
};

export const me = (token) => {

  let config = {
      headers: {
          "Authorization":"Bearer "+token,
      }
  }

  return dispatch => {
      api.get(`me`,config)
          .then(response => {
              localStorage.setItem('access_token', token);
              localStorage.setItem('token_type',`Bearer`)
              localStorage.setItem('user',response.data.hash_id)
              dispatch(authSuccess(token));
              dispatch(setMe(response.data));
          })
          .catch(errors => {
              localStorage.removeItem('access_token');
              localStorage.removeItem('user');
              console.log('prova',errors);
              dispatch(authFail(errors));
              
          });
  };


}

// const me = () => {
//     return dispatch => {
//         api.get(`${process.env.REACT_APP_API_URL}api/user`)
//             .then(response => {
//                 dispatch(setMe(response.data));
//             })
//             .catch(errors => {
//                 console.log(errors);
//                 dispatch(authFail(errors));
//             });
//     };
// };
//
export const setMe = (me) => {
    return {
        type: actionTypes.SET_ME,
        me: me
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        dispatch(authStart());
        const access_token = localStorage.getItem('access_token');
        const refresh_token = localStorage.getItem('refresh_token');
        const expires_in = localStorage.getItem('expires_in');
        const token_type = localStorage.getItem('token_type');
        if (!access_token) {
            dispatch(logout());
        } else {
            dispatch(authSuccess(
                access_token,
                refresh_token,
                expires_in,
                token_type));
            dispatch(me(access_token))
        }
    };
};

const changePasswordStart = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_START
    };
};

export const changePassword = (password, newPassword, passwordConfirmation) => {
    return dispatch => {
        dispatch(changePasswordStart());
        const data = {
            old_password: password,
            new_password: newPassword,
            new_password_confirmation: passwordConfirmation
        }
        api.put(`change-password`, data)
            .then(() => {
                dispatch(Notifications.success({
                    message: 'Password cambiata',
                }))
                dispatch(changePasswordSuccess())
            })
            .catch(errors => {
                dispatch(Notifications.error({
                    message: 'Errore modifica password',
                }));
                dispatch(changePasswordFail(errors));
            });
    };
};

const changePasswordSuccess = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_SUCCESS
    };
};

const changePasswordFail = (error) => {
    return {
        type: actionTypes.CHANGE_PASSWORD_FAILED,
        error: error
    };
};
